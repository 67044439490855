import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'
import otpConstants from 'modules/base/config/otpConstants'
export default (api: EagleApiInterface) => {
  api.collection.siteAdminAccountApi = <EagleModuleApiInterface>{
    readProfile: () => api.request({
      url: `/api/admin/site-admin/account/profile`,
      type: 'get',
    }),
    updateProfile: (params: AnyObject) => api.request({
      url: `/api/admin/site-admin/account/profile`,
      type: 'put',
      params,
    }),
    csrf: () => api.request({
      url: `/api/admin/site-admin/account/csrf`,
      type: 'post',
    }),
    verify: (password: any) => api.request({
      url: `/api/admin/site-admin/account/verify`,
      type: 'post',
      params: {password},
    }),
    login: (params: AnyObject, keepLogin: boolean) => api.request({
      url: `/api/admin/site-admin/account/login`,
      type: 'post',
      params: {
        ...params,
        keep_login: keepLogin,
      },
    }),
    renewToken: (force = false) => api.request({
      url: `/api/admin/site-admin/account/renew-token`,
      type: 'put',
      params: {
        force,
      },
    }),
    logout: () => api.request({
      url: `/api/admin/site-admin/account/logout`,
      type: 'post',
    }),
    sendResetPasswordEmail: (email: string, recaptcha: string|null) => api.request({
      url: `/api/admin/site-admin/account/send-reset-password-email`,
      type: 'put',
      params: {
        email,
        recaptcha,
      },
    }),
    loginViaOtp: (params: AnyObject) => api.request({
      url: `/api/admin/site-admin/account/login-via-otp`,
      type: 'post',
      params,
    }),
    resetPassword: (password: string, password_confirm: string) => api.request({
      url: `/api/admin/site-admin/account/password`,
      type: 'put',
      params: {
        password,
        password_confirm,
      },
    }),
  }

  api.collection.siteAdminApi = <EagleModuleApiInterface>{
    roles: () => api.request({
      url: `/api/admin/site-admin/roles`,
      type: 'get',
    }),
    read: (id: string) => api.request({
      url: `/api/admin/site-admin/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/site-admin/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/site-admin`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/site-admin/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/site-admin/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/site-admin/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/site-admin/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/site-admin/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    resetPassword: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/site-admin/${id}/password`,
      type: 'put',
      params,
    }),
  }

  api.collection.siteAdminGroupApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/admin/site-admin-group/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/site-admin-group/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/site-admin-group`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/site-admin-group/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/site-admin-group/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/site-admin-group/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/site-admin-group/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/site-admin-group/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  api.collection.siteAdminDeviceApi = <EagleModuleApiInterface>{
    index: (params = {}) => api.request({
      url: `/api/admin/site-admin-device/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/site-admin-device/${id}`,
      type: 'delete',
    }),
  }

  api.collection.siteAdminLogApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/admin/site-admin-log/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/site-admin-log/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/site-admin-log/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/site-admin-log/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
